<template>
  <div>
    <molecules-custom-dialog
      v-if="showApprove"
      :show="showApprove"
      title="Sudahkah anda cek datanya?"
      caption="Pastikan identitas kandidat ini sesuai dengan ktp-nya!"
      :items="[
        {
          title: 'Batalkan',
          full: false,
          exec: () => (showApprove = null),
        },
        {
          title: 'Approve',
          override: 'red--text',
          full: false,
          exec: () => {
            approve(showApprove)
            showApprove = null
          },
        },
      ]"
    />
    <molecules-custom-dialog
      v-if="showReject"
      :show="showReject"
      title="Alasan Reject"
      caption="Berikan alasan anda"
      :items="[
        {
          title: 'Batalkan',
          override: 'transparent',
          full: false,
          exec: () => (showReject = null),
        },
        {
          title: 'Reject',
          override: 'red--text',
          full: false,
          exec: () => {
            reject(showReject)
            showReject = null
          },
        },
      ]"
    >
      <atoms-text-field color="background" label="Alasan Reject" :noLabel="true" v-model="rejectNote"
    /></molecules-custom-dialog>
    <v-row>
      <v-col cols="12" md="4">
        <molecules-summary-card
          icon="account-multiple"
          :title="`${$store.state.worker.total}<sup>+${
            ($store.state.worker.currentDay && $store.state.worker.currentDay.total) || 0
          }</sup> Pekerja`"
          :desc="`Sampai Tanggal ${$moment().format('DD MMMM YYYY')}`"
          :loading="reportLoading"
        />
      </v-col>
      <v-col cols="12" md="4">
        <molecules-summary-card
          icon="account-multiple-plus"
          color="green"
          :title="`${($store.state.worker.currentDay && $store.state.worker.currentDay.total) || 0} Terdaftar Hari Ini`"
          :desc="`${$moment().format('DD MMMM YYYY')}`"
          :loading="reportLoading"
        />
      </v-col>
      <v-col cols="12" md="4">
        <molecules-summary-card
          icon="account-clock"
          color="orange"
          :title="`${
            ($store.state.worker.within7Days &&
              $store.state.worker.within7Days.map((x) => x.total).reduce((x, y) => x + y, 0)) ||
            0
          } Pekerja Terdaftar`"
          :desc="`Dalam 7 Hari Terakhir`"
          :loading="reportLoading"
        />
      </v-col>
    </v-row>

    <v-form @submit.prevent="loadData" id="filter" class="mt-4">
      <v-row justify="space-between">
        <v-col cols="12" lg="10">
          <atoms-text-field class="rounded-lg accent" noLabel>
            <v-text-field
              v-model.trim="term"
              :style="{
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }"
              @click:append="
                () => {
                  term = ''
                }
              "
              placeholder="Ketikkan kata kunci pencarian..."
              appendIcon="mdi-close"
              class="rounded-lg ma-0 pa-0"
              hide-details
              flat
              solo
              dense
            >
              <template v-slot:prepend>
                <v-combobox
                  class="rounded-lg ma-0 pa-0"
                  placeholder="Query"
                  v-model="searchFilter"
                  :items="searchFilters.map((x) => x.title)"
                  hide-details
                  multiple
                  dense
                  solo
                  flat
                  required
                >
                  <template v-slot:selection="{ item, index }">
                    <span style="white-space: nowrap" class="pa-0"
                      >{{ (index == 0 && item) || (index == 1 && '..') || '' }}
                    </span>
                  </template></v-combobox
                >
              </template></v-text-field
            ></atoms-text-field
          >
        </v-col>
        <v-col cols="6" md="2" class="d-flex justifyx-space-between">
          <atoms-button type="submit" style="width: 100%" class="primary lighten-5 primary--text"
            ><v-icon left>mdi-magnify</v-icon>Cari Pekerja</atoms-button
          >
        </v-col>
        <v-col cols="12" md="auto">
          <atoms-button
            class="primary"
            style="width: 100%"
            @click="
              () => {
                sort = sort === constant[0] ? constant[1] : constant[0]
                getWorkers()
              }
            "
            ><v-icon left>mdi-filter-variant-{{ sort === constant[0] ? 'minus' : 'plus' }}</v-icon
            >Sort Tanggal Daftar : {{ sort }}</atoms-button
          >
        </v-col>
        <v-col cols="12" md="auto">
          <v-combobox
            class="rounded-lg"
            placeholder="Filters"
            prepend-inner-icon="mdi-filter-variant"
            v-model="filter"
            :items="filters.map((x) => x.title)"
            @change="loadData"
            hide-details
            multiple
            dense
            solo
            flat
            required
            :style="{
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }"
        /></v-col>
      </v-row>
    </v-form>

    <div class="mt-4">
      <div v-if="mainLoading">
        <v-skeleton-loader
          v-for="i in 5"
          :key="i"
          type="list-item-avatar"
          class="accent rounded-lg mb-2 pa-2"
          :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          }"
        />
      </div>
      <div v-else>
        <div v-if="$store.state.worker.data && $store.state.worker.data.length > 0">
          <!-- <atoms-text class="pa-2"
            ><v-icon>mdi-magnify</v-icon>Hasil Pencarian, {{ items.count }} data
            ditemukan</atoms-text
          > -->
          <div
            v-for="({ ...rest }, i) in $store.state.worker.data || []"
            :key="i"
            :class="['accent', 'rounded-lg pa-4 mb-2']"
            :style="{
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }"
          >
            <v-row align="center">
              <div v-if="rest.active === true">
                <v-chip v-if="rest.approved === false" class="ml-4" pill color="red darken-2 white--text"
                  >Rejected</v-chip
                >
                <v-chip v-else-if="rest.approved === true" class="ml-4" pill color="teal darken-2 white--text"
                  >Approved</v-chip
                >
                <v-chip v-else class="d-none" color="transparent"></v-chip>
              </div>
              <v-col class="shrink">
                <v-avatar color="grey lighten-4 rounded-circle">
                  <atoms-image :enableZoom="true" v-if="rest.photo" :src="rest.photo" />
                  <v-icon light v-else>mdi-account</v-icon></v-avatar
                >
              </v-col>
              <v-col cols="8" md="4">
                <atoms-text :auto="true" class="font-weight-bold"
                  >{{ $toCapitalize((rest.fullName && rest.fullName) || 'Nama tidak dicantumkan') }}
                </atoms-text>
                <atoms-text class="primary--text">{{ (rest && rest.email) || 'pekerja.email@website.com' }}</atoms-text>
              </v-col>
              <v-col align="start">
                <atoms-text class="font-weight-bold"
                  >Tanggal Daftar {{ $moment(rest._createdDate).format('dddd, DD MMMM YYYY') }}</atoms-text
                >
              </v-col>
              <v-col align="center">
                <atoms-button
                  @click="
                    $viewerApi({
                      options: {
                        toolbar: false,
                      },
                      images: [rest.photoId],
                    })
                  "
                >
                  Lihat Foto KTP
                </atoms-button>
              </v-col>
              <v-col class="shrink">
                <v-menu offset-x class="rounded-xl">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                  </template>
                  <v-list nav dense elevation="0" class="text-center rounded-lg">
                    <v-list-item link @click="showApprove = rest._id" class="rounded-lg px-4 text-center">
                      <v-icon left>mdi-account-check</v-icon>
                      <v-list-item-title class="px-2">Approve</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="showReject = rest._id" class="rounded-lg px-4 text-center">
                      <v-icon left>mdi-account-remove</v-icon>
                      <v-list-item-title class="px-2">Reject</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="showDetail = rest" class="rounded-lg px-4 text-center">
                      <v-icon left>mdi-account-details</v-icon>
                      <v-list-item-title class="px-2">Detail</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="showEdit = rest" class="rounded-lg px-4 text-center">
                      <v-icon left>mdi-account-edit</v-icon>
                      <v-list-item-title class="px-2">Edit</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            <br />
            <atoms-container class="px-0 pb-0">
              <atoms-text><v-icon left small>mdi-card-text</v-icon> KTP : {{ rest.idNumber || '-' }}</atoms-text>
              <atoms-text
                ><v-icon left small>mdi-map-marker</v-icon>
                {{ rest.address && rest.address.streetAddress && rest.address.streetAddress + ',' }}
                {{ rest.address && rest.address.city && rest.address.city + '-' }}
                {{ (rest.address && rest.address.province) || 'Provinsi Tidak Dicantumkan' }}</atoms-text
              >
              <atoms-text
                ><v-icon left small>mdi-phone</v-icon>
                {{ rest.phone || '-' }}
              </atoms-text>
              <atoms-text
                ><v-icon left small>mdi-account-check</v-icon>
                {{ rest.setStatusBy || '-' }}
              </atoms-text>
              <div v-if="rest.approved === false" class="red lighten-1 white--text pa-3 mt-3 mb-1 rounded">
                <p class="text-h6 font-weight-medium ma-0 pa-0">Alasan Reject :</p>
                <p class="text-caption ma-0 pa-0 mb-2 ml-2">
                  {{ rest.accountNotes }}
                </p>
              </div>
            </atoms-container>
          </div>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination class="my-4" v-model="page" :length="$store.state.worker.pagesLength"></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </div>
        <molecules-wrapper v-else class="d-flex justify-center align-center my-8 py-8 outlined">
          <div class="text-center">
            <atoms-image :width="250" src="/assets/missing.svg" />
            <atoms-title :h3="true" class="mt-6">Tidak ada pekerja yang ditemukan!</atoms-title>
          </div>
        </molecules-wrapper>
      </div>
    </div>
    <!-- detail -->
    <v-slide-x-reverse-transition>
      <organism-workers-edit
        v-if="showDetail"
        :data="showDetail"
        :newWorker="true"
        @closed="
          () => {
            showDetail = null
          }
        "
      />
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition>
      <organism-workers-edit
        v-if="showEdit"
        :data="showEdit"
        :pureEdit="true"
        :newWorker="true"
        @closed="
          () => {
            showEdit = null
          }
        "
      />
    </v-slide-x-reverse-transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      term: '',
      sort: '',
      page: 1,
      limit: 10,
      showReject: null,
      rejectNote: '',
      showApprove: null,
      searchFilter: null,
      searchFilters: [
        {
          title: 'Nama Kandidat',
          value: (term) => ({
            fullName: {
              $regex: term || '',
              $options: 'i',
            },
          }),
        },

        {
          title: 'NIK Pekerja',
          value: (term) => ({
            idNumber: {
              $regex: term || '',
              $options: 'i',
            },
          }),
        },
        {
          title: 'Nomor Pekerja',
          value: (term) => ({
            phone: {
              $regex: term || '',
              $options: 'i',
            },
          }),
        },
        {
          title: 'Email Pekerja',
          value: (term) => ({
            email: { $regex: term || '', $options: 'i' },
          }),
        },
      ],
      filter: null,
      filters: [
        {
          title: this.$status.belum,
          value: {
            active: { $ne: true },
          },
        },
        {
          title: this.$status.approve,
          value: {
            approved: { $eq: true },
          },
        },
        {
          title: this.$status.reject,
          value: {
            approved: { $eq: false },
          },
        },
        {
          title: this.$status.semua,
          value: {
            approved: undefined,
            active: undefined,
          },
        },
        {
          title: 'Sudah Menikah',
          value: (term) => ({
            statusMarried: this.$statusMarried.Married,
          }),
        },
        {
          title: 'Tunjukkan PHL',
          value: (term) => ({
            agreeTermPhl: true,
          }),
        },
        {
          title: 'Tunjukkan Blacklist',
          value: (term) => ({
            blacklist: true,
          }),
        },
      ],
      showDetail: null,
      showEdit: null,
      constant: ['Desc', 'Asc'],
      reportLoading: false,
      mainLoading: false,
    }
  },
  watch: {
    page: {
      handler() {
        this.getSummary()
        this.getWorkers()
      },
      deep: true,
    },
  },
  async mounted() {
    this.filter = [this.$status.belum]
    this.sort = this.constant[0]
    this.loadData()
  },
  methods: {
    loadData() {
      this.page = 1
      this.getWorkers()
      this.getSummary()
    },
    async getSummary() {
      this.reportLoading = true
      try {
        await this.$store.dispatch('worker/summary')
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.reportLoading = false
      }
    },
    async getWorkers() {
      this.mainLoading = true
      console.log({
        pipeline: [
          {
            $sort: {
              _createdDate: this.sort === this.constant[0] ? -1 : 1,
            },
          },
          {
            $lookup: {
              from: 'WorkerDetails',
              localField: 'detailId',
              foreignField: '_id',
              as: 'detail',
            },
          },
          {
            $unwind: {
              path: '$detail',
              preserveNullAndEmptyArrays: true,
            },
          },
          {
            $match: {
              ...(this.filter && this.filter.length > 0
                ? Object.assign({}, ...this.filter?.map((x) => this.filters?.find((y) => y.title === x)?.value))
                : []),
              $or: [
                ...(this.searchFilter?.length > 0
                  ? this.searchFilter?.map((x) => this.searchFilters?.find((y) => y.title === x)?.value?.(this.term))
                  : this.searchFilters.map((x) => x?.value?.(this.term))),
              ],
            },
          },
        ],
      })
      try {
        await this.$store.dispatch('worker/getAll', {
          custom: {
            pipeline: [
              {
                $sort: {
                  _createdDate: this.sort === this.constant[0] ? -1 : 1,
                },
              },
              {
                $lookup: {
                  from: 'WorkerDetails',
                  localField: 'detailId',
                  foreignField: '_id',
                  as: 'detail',
                },
              },
              {
                $unwind: {
                  path: '$detail',
                  preserveNullAndEmptyArrays: true,
                },
              },
              {
                $match: {
                  ...(this.filter && this.filter.length > 0
                    ? Object.assign({}, ...this.filter?.map((x) => this.filters?.find((y) => y.title === x)?.value))
                    : []),
                  $or: [
                    ...(this.searchFilter?.length > 0
                      ? this.searchFilter?.map((x) =>
                          this.searchFilters?.find((y) => y.title === x)?.value?.(this.term),
                        )
                      : this.searchFilters.map((x) => x?.value?.(this.term))),
                  ],
                },
              },
            ],
          },
          page: this.page,
          limit: this.limit,
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.mainLoading = false
      }
    },
    async approve(id) {
      // code
      this.$store.commit('SET', { loading: true })
      try {
        await this.$store.dispatch('worker/update', {
          _id: id,
          approved: true,
          active: true,
          accountNotes: '',
          setStatusBy: this.$store.state.user?.email,
        })
        this.$showDialog({
          title: 'Berhasil',
          body: 'Approve berhasil dilakukan!',
        })
        this.loadData()
      } catch (error) {
        Promise.reject(error)
      }
      this.$store.commit('SET', { loading: false })
    },
    async reject(id) {
      // code
      this.$store.commit('SET', { loading: true })
      try {
        await this.$store.dispatch('worker/update', {
          _id: id,
          approved: false,
          active: true,
          accountNotes: this.rejectNote,
          setStatusBy: this.$store.state.user?.email,
        })
        this.$showDialog({
          title: 'Berhasil',
          body: 'Reject berhasil dilakukan!',
        })
        this.loadData()
      } catch (error) {
        Promise.reject(error)
      }
      this.$store.commit('SET', { loading: false })
    },
  },
}
</script>
